.doctorsprofilecontainer{
    width: 100vw;
    min-height: 100vh;
    
   /* padding: 0 20px; */
   margin-bottom: 1.5rem;
   
}
.heading{
    font-size: 30px;
    text-align: center;
   /* border-bottom: 2px solid gray; */
   color: #9959FF;
   margin-bottom: 20px;
   margin-top: 10px;
}
.innerContainer{
    display: grid;
    grid-template-columns: repeat(3, minmax(200px, 1fr));
    gap: 2rem;
    height: 100vh;
    padding: 2rem 3rem;

}
@media screen and (max-width: 768px) {
    .innerContainer {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  