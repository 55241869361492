 #menu__toggle {
  opacity: 0;
}

#menu__toggle:checked ~ .menu__btn > span {
  transform: rotate(45deg);
}
#menu__toggle:checked ~ .menu__btn > span::before {
  top: 0;
  transform: rotate(0);
}
#menu__toggle:checked ~ .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
}
#menu__toggle:checked ~ .menu__box {
  visibility: visible;
  right: 0;
  padding: 4.5rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
#menu__toggle:checked ~ .menu-overlay {
  opacity: 1;
  pointer-events: auto;
}



.menu__btn {
  display: flex;
  align-items: center;
  position: absolute;
  top: 9px;
  right: 7px;

  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 3;
}

.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;

  width: 100%;
  height: 2px;

  background-color: #616161;

  transition-duration: 0.6s;
}
.menu__btn > span::before {
  content: '';
  top: -8px;
}
.menu__btn > span::after {
  content: '';
  top: 8px;
}



.menu__box {
  padding: 4.5rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: fixed;
  z-index: 2;
  visibility: hidden;
  top: 0;
  right: -100%;

  width: 300px;
  height: 100%;

  margin: 0;
  padding: 80px 0;

  list-style: none;

  background-color: #fff;
  box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);

  transition-duration: 0.6s;
}

.menu_item {
  display: block;
  padding: 0.2rem 0.1rem;
  color: #4B4B4B;
  font-size: 19px;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  transition-duration: .25s;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.menu_item a{
  display: block;
  padding: 0.2rem 0.1rem;
  color: #4B4B4B;
  font-size: 19px;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  transition-duration: .25s;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.menu_item a:hover {
  color: #8621de;
}

.menu_item:hover {
  color: #8621de;
}

 .menu-overlay {
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.9s;
}


 

  .navbar {
    background-color: #060b26;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  .menu-bars {
    margin-left: 2rem;
    color: #1a83ff;
    font-size: 2rem;
    background: none;
  }
  
  .nav-menu {
    background-color: #ffffff;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 100%;
    transition: 850ms;
  }
  
  .nav-menu.active {
    left: 56%;
    transition: 350ms;
  }

  


  
  .nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
  }
  
  .nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }
  
  .nav-text a:hover {
    background-color: #1a83ff;
  }
  
  .nav-menu-items {
    width: 100%;
  }
  
  .navbar-toggle {
    background-color: #060b26;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  span {
    margin-left: 16px;
  }