
:root{
    --backgroundColor: #DBDFEA;
    --carcontainerbg:#FFEAD2;
    --fontfamily_1:'Noto Sans', sans-serif;
    --fontfamily_2:'Open Sans', sans-serif;
}

*{
    margin:  0;
    padding: 0;
    box-sizing: border-box;
}
body{
    overflow-x: hidden;
}

/* ################################################################################### */

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
  }
  body{
    font-family: 'Montserrat', sans-serif;
    font-size: 0.9rem;
    overflow-x: hidden;
  }

  :root {
    --primary-hue: 270;
    --black-lightness: 15%;
    --dark-lightness: 44%;
    --light-lightness: 94%;
    --white-lightness: 100%;
    --color-primary: hsl(var(--primary-hue), 89%, 41%);
    --color-white: hsl(0, 5%, var(--white-lightness));
    --color-light: hsl(var(--primary-hue), 5%, var(--light-lightness));
    --color-black: hsl(var(--primary-hue), 5%, var(--black-lightness));
    --color-dark: hsl(var(--primary-hue), 5%, var(--dark-lightness));
  

    --container-width-lg: 76%;
    --container-width-md: 90%;
  
 
    --border-radius-1: 1.2rem;
    --border-radius-2: 0.8rem;
    --border-radius-3: 0.5rem;
  
    --transition: all 500ms ease;
  }


.main_container {
    width: var(--container-width-lg);
    margin-inline: auto;
    max-width: 1920px;
  }

 
section {
    padding: 5rem 0;
    background: rgb(236, 234, 241);
    transition: var(--transition);
  }
  
  section > h2 {
    text-align: center;
  }
  
  section > p {
    text-align: center;
    margin: 0.5rem auto 4rem;
    width: 40%;
  }

  a {
   
    transition: var(--transition);
  }