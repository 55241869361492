.mainCardContainer{
    height: 200px;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    
    justify-content: space-between;
    padding: 20px 20px;
    box-shadow: 5px 5px 10px 5px rgba(195, 183, 195, 0.6);
    width: 100%;
    box-sizing: border-box;
}
.icondiv{
    margin-top: 1.5rem;
    flex-basis: 100%; 
}
.innericons{
    display: flex;
    align-items: center;
    gap: 5px;
    
}
.icon{
    color: #9959FF;
}

.imgdiv{
    height: 80px;
    width: 80px;
    object-fit: cover;
    border-radius: 50%;
   
}
.imgdiv img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.rightdiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
}
.btn{
    border: none;
    padding: 5px 10px;
    border-radius: 10px;
    width: fit-content;
    background-color: #8C50EA;
    color: white;
    font-weight: bold;
    cursor: pointer;
}


@media screen and (max-width:768px) {
    .mainCardContainer{
        flex-direction: column-reverse;
        gap: 20px;
    }
    .leftdiv{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .icondiv{
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .rightdiv{
        display: flex;
        justify-content: center;
    }
  
}
