.form_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
 
    background-color:rgb(243, 236, 254);
    padding-bottom: 2rem;
}
  
.form {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form div {
  margin-bottom: 20px;
  width: 70%;
}

.form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form input {
  width: 100%;
  max-width: 300px;
  min-width: 300px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.about{
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  height: fit-content;
  max-height: 100px;
  min-height: 50px;
  width: 70%;
  max-width: 300px;
  min-width: 300px;
  font-family: 'Noto Sans', sans-serif;
}

.form button {
  padding: 10px 20px;
  background-color: #7F39FB;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form button:hover {
  background-color: #5a21bd;
}
  