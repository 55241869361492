.sec2{
    background: rgb(240,241,202);
background: linear-gradient(90deg, rgba(240,241,202,0.7485119047619048) 0%, rgba(212,202,238,1) 20%);
}
.homepg-tech-div2 p{
    padding-top: 2rem;
    width: 500px;
}
.tech__container{
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}

@media (max-width: 1024px) {
    .tech__container{

        padding: 0rem 2rem;
    }
}


@media (max-width: 768px) {
    .tech__container{
        
        align-items: center;
        padding: 0rem 3rem;
    }
    .homepg-tech-div1 img{
        width: 200px;
    }
}


@media (max-width: 640px) {
    
    .homepg-tech-div2 p{
        max-width: 400px;
    }
    .tech__container{
        flex-direction: column;

        padding: 0rem 1rem;
    }
    .homepg-tech-div1 img{
        width: 200px;
    }
    .homepg-tech-div2{
        padding: 0rem 2rem !important;
    }
}

@media (max-width: 475px) {
    
    .homepg-tech-div2 p{
        max-width: 300px;
    }
}
@media (max-width: 385px) {
    
    .homepg-tech-div2 p{
        max-width: 300px;
    }
    .homepg-tech-div1 img{
        width: 200px;
    }
    .homepg-tech-div1{
        align-items: center !important;
    }
}