

.footer{
    background: linear-gradient(180deg, rgba(189, 57, 251, 0.57) 0%, rgba(166, 57, 251, 0.78) 33.85%, #7F39FB 100%);
    height: 200px;
    position: relative;
    bottom: 0px;
}

.footer-container{
    display: flex;
    padding: 1.5rem 6rem;
    justify-content: space-between;
    
}
.footer-div1{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.footer-div1 p{
    font-weight: 400;
    color: white;
    width: 300px;
}
.footer__socials{
    padding-top: 1rem;
    display: flex;
    gap: 1rem;
    
}
.footer-div2 h4{
    color: white;
}


.social{
    padding-top: 1rem;
}
.footer__socials a:hover {
    fill-opacity: 0.7;
    transform: translateY(-0.5rem);
}
.footer__socials a {
    background: #7F39FB;
    color: white;
    padding: 0.6rem;
    border-radius: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer__menu{
    display: flex;
    color: white;
    gap: 0.5rem;
}
.foot-item a{
    color: white;
   
}
.footer__menu link{
    color: white;
    
}
.footer__copyright{
    color: white;
    padding-top: 1rem;
}

@media screen and (max-width: 1024px) {
    .footer-container{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 300px;
        gap: 1 rem;
    }
    .footer{
        height: 342px;
    }
    .social{
        padding-top: 0.5rem;
    }
    .footer-div2 h4{
        padding-top: 1rem;
    }
    .footer-div3 {
        padding-top: 1rem;
    }
    
  }

  /* xs */
@media (max-width: 475px) {
    .footer-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding: 1.5rem;
        height: 300px;
    }
}
  @media (max-width: 385px) {
    .footer{
        background: linear-gradient(180deg, rgba(189, 57, 251, 0.57) 0%, rgba(166, 57, 251, 0.78) 33.85%, #7F39FB 100%);
        position: relative;
        bottom: 0px;
        height: 22rem;
    }
    .footer__socials{
        padding-top: 1rem;
        display: flex;
        flex-wrap:wrap;
        gap: 0.5rem;
    }
    .footer-div1 p{
        font-size: 0.7rem;
    width: 14rem;
    }
    .footer-div1 img{
    width: 6rem;
    }
    .footer__menu{
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
    }
  }