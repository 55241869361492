
.about{
    padding-top: 6rem;
    
}
.about_container{
    width: auto;
    
    background: #F5F5F5;
    padding-top: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
.about_container h2{
  padding-top: 17rem;
  padding-bottom: 1rem;
  font-weight: 800;
}
.about_container p{
  padding-bottom: 4rem;
}

/* ############### card ############# */
.card {
    max-width: 250px;
    height: 300px;
    cursor: pointer;
    border-radius: 0.5rem;
    background-color: #f7f5f5;
    
    filter: drop-shadow(42px 119px 50px rgba(36, 33, 42, 0.02)) drop-shadow(23px 67px 42px rgba(36, 33, 42, 0.07)) drop-shadow(10px 30px 31px rgba(36, 33, 42, 0.11)) drop-shadow(3px 7px 17px rgba(36, 33, 42, 0.13)) drop-shadow(0px 0px 0px rgba(36, 33, 42, 0.13));
    border: 1px solid transparent;
    box-shadow: 3px 9px 6px 4px rgba(0,0,0,0.1);
  }
  
  .content {
    padding: 2rem;
  }
  .content .desc{
    font-family: 'Noto Sans', sans-serif;
    color: rgb(120, 108, 108);
    margin-top: 0.5rem;
    font-size: 0.875rem;
    line-height: 1rem;
    
  }
  
  .about-title {
    color: #111827;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 600;

  }
  

  
  .action {
    display: inline-flex;
    margin-top: 0.5rem;
    color: #ffffff;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    align-items: center;
    gap: 0.25rem;
    background-color: #2563EB;
    padding: 4px 8px;
    border-radius: 4px;
  }
  
  .action span {
    transition: .3s ease;
  }
  
  .action:hover span {
    transform: translateX(4px);
  }
  

  .cards{
    position: absolute;
    
    z-index: 1;
    padding-bottom: 34rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 4rem;
  }
  

  .about-des p{
    text-align: center;
  }

  @media (max-width: 1024px) {
    .about_container{
      padding-left: 2rem;
      padding-right: 2rem;
    }
    .cards{
      gap: 1rem;
    }
  }




  @media (max-width: 768px) {
    .about_container{
      padding: 0rem 2rem;
    }
    .card {
      max-width: 196px;
      height: 226px;
    }
    .content {
      padding: 1rem;
    }
    .content .desc{
      margin-top: 0.5rem;
      font-size: 0.8rem;
      line-height: 0.89rem;
      
    }
    .about-title {
      font-size: 1.125rem;
      line-height: 1.75rem;  
    }
    .about_container{
      padding-top: 10rem;
    }
    .about_container h2{
      padding-top: 12rem;
      padding-bottom: 1rem;
      font-weight: 800;
    }
    .about-div1 {
      
  }
  }

  @media (max-width: 652px){
    .cards{
      display: none;
    }
    .about_container h2 {
      padding-top: 0rem;
  }
  } 
  /* sm */
  @media (max-width: 640px) {
    /* .card {
      max-width: 224px;
      height: 234px;
    }
    .cards{
      position: absolute;
      z-index: 1;
      padding-bottom: 0rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      top: 43rem;
    } */
    .content {
      padding: 1rem;
    }
    .content .desc{
      margin-top: 0.5rem;
      font-size: 0.8rem;
      line-height: 1.1rem;
      
    }
    .about-title {
      font-size: 1.2rem;
      line-height: 1.75rem;  
    }
    .about_container{
      /* width: auto; */
      height: auto;
      /* background: rgb(241, 240, 243); */
      padding-top: 4rem;
      justify-content: center;
      align-items: center;
    }

  }
  
  /* xs */
  @media (max-width: 475px) {
    /* .card {
      max-width: 224px;
      height: 234px;
    } */

    .about-des p{
      font-size: 0.7rem;
      max-width: 378px;;
      padding-right: 1rem;
      padding-left: 1rem;
    }
    
    .about_des_br{
      display: none;
    }
    .about_container h2 {
      padding-top: 0rem;
  }
  .about_container{
    padding-top: 4rem;
  }

  }
  