.aboutus-sec {
    background: #F3ECFE;
}


.aboutus_container{
    display: flex;
    flex-direction: column;
}
.about-div1{
    display: flex;
    justify-content: space-around;
    gap: 5rem;
    align-items: center;
    padding: 2rem 3rem;
}

.abt-missionsvg img{
    width: 400px;
}
.abt-txt h1{
    font-size: 2.5rem;
    padding-bottom: 1rem;
}
.abt-txt p{
    font-weight: 600;
    color: #747070;
    font-family: var(--fontfamily_1);
    line-height: 20px;
    font-family: 'Poppins', sans-serif;

}


/* ################################# div2 ####################################### */

.about-div2{
    display: flex;
    justify-content: space-between;
    gap: 5rem;
    align-items: center;
}

.abt-storysvg img{
    width: 400px;
}



/* ################################# div3 ####################################### */
.about-div3{
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 3rem;

}
.about-div3 h1{
    font-size: 35px;
}

.abt-value-cards{
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.abt-value-card{
    background: var(--color-white);
    padding: 1.5rem;
    border: 2px solid transparent;
    border-radius: var(--border-radius-2);
    transition: var(--transition);
    max-width: 310px;
   

}
.abt-value-card p{
   
    font-family: 'Poppins', sans-serif;
    padding-top: 0.5rem;
}


.abt-value-card:hover {
    background: transparent;
    border-color: var(--color-white);
    transform: translateY(-0.5rem);
}

/* ################################# div4 ####################################### */



.about-div4{
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 3rem;

}

.about-div4 h1{
    font-size: 35px;
}

@media (max-width: 890px){
    .about-div1{
      gap: 3rem;
    }
    .abt-missionsvg img {
        width: 300px;
    }

    
  }


@media (max-width: 1024px) {
    .main_container{
        width: auto;
    }
}


@media (max-width: 770px) { 
    .main_container{
        width: auto;
    }
    .abt-missionsvg img{
        width: 350px;
    }
    .about-div1{
        flex-direction: column;
    }
    .about_flex{
        flex-direction: column-reverse !important;
    }
}
@media (max-width: 830px) { 
    .abt-value-cards{
        
        flex-wrap: wrap;
    }
}

/* sm */
@media (max-width: 640px) {
    .about-div1{
        gap: 1rem;
       
    }
    .abt-missionsvg img{
        width: 300px;
    }


}

/* xs */
@media (max-width: 475px) {

    .abt-value-cards{
        flex-direction: column;
    }
    .about-div4{
        align-items: center;
    }
    .abtcardWrapper{
        flex-direction: column;
        align-items: center;
    }
    .abt-value-cards{
        align-items: center;
        flex-wrap: wrap;
    }

}
  @media (max-width: 385px) {
  
  }