.sec{
    background: rgb(220,212,241);
    background: linear-gradient(90deg, rgba(220,212,241,1) 0%, rgba(115,67,237,0.7485119047619048) 98%);
}
.talk-container{
    display: flex;
    justify-content: space-between;
    
}

    
.talk-container h3{
    text-align: start;
}
.talk-container span{
    padding-top: 2rem;
}
.hp-talk-div{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: flex-end;
  gap: 1rem; 
}




.button-61 {
    width: 10rem;
  align-items: center;
  appearance: none;
  border-radius: 4px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 1px -2px,rgba(0, 0, 0, .14) 0 2px 2px 0,rgba(0, 0, 0, .12) 0 1px 5px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: Roboto,sans-serif;
  font-size: .875rem;
  font-weight: 500;
  height: 36px;
  justify-content: center;
  letter-spacing: .0892857em;
  line-height: normal;
  min-width: 64px;
  outline: none;
  overflow: visible;
  padding: 0 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  will-change: transform,opacity;
}

.button-61:hover {
  box-shadow: rgba(0, 0, 0, .2) 0 2px 4px -1px, rgba(0, 0, 0, .14) 0 4px 5px 0, rgba(0, 0, 0, .12) 0 1px 10px 0;
}

.button-61:disabled {
  background-color: rgba(0, 0, 0, .12);
  box-shadow: rgba(0, 0, 0, .2) 0 0 0 0, rgba(0, 0, 0, .14) 0 0 0 0, rgba(0, 0, 0, .12) 0 0 0 0;
  color: rgba(0, 0, 0, .37);
  cursor: default;
  pointer-events: none;
}

.button-61:not(:disabled) {
  background-color: #6200ee;
}

.button-61:focus {
  box-shadow: rgba(0, 0, 0, .2) 0 2px 4px -1px, rgba(0, 0, 0, .14) 0 4px 5px 0, rgba(0, 0, 0, .12) 0 1px 10px 0;
}

.button-61:active {
  box-shadow: rgba(0, 0, 0, .2) 0 5px 5px -3px, rgba(0, 0, 0, .14) 0 8px 10px 1px, rgba(0, 0, 0, .12) 0 3px 14px 2px;
  background: #A46BF5;
}





/* md */
@media (max-width: 768px) {
  .talk-container{
   
    align-items: center;
    gap: 2rem;
  }
}


@media (max-width: 640px) {

  .talk-container{
    flex-direction: column;
    align-items: center;
    padding: 0rem 2rem;

    gap: 2rem;
  }
}

@media (max-width: 475px) {
  .hp-talk-img img{
    width: 200px;
  }
  .talk-container{
    padding: 0rem 1rem;
  }
  .hp-talk-div p{
    font-size: 0.8rem !important;
    line-height: 0.9rem !important;
  }
}