.team{
    padding-top: 6rem;
}
.hp_team_div11 p,h2{
    padding-top: 1rem;
    
}
.hp_team_div11{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.hp_team_div1{
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  align-items: center;
}
.hp_team_div12 img{
  width: 400px;
}




.team_container{
    display: flex;
    flex-direction: column;
    gap: 1rem;

}


@media (max-width: 1024px) {
  .team_container{

    padding: 0rem 2rem;
  
  }
}


@media (max-width: 768px) {
  .hp_team_div12 img{
    width: 300px;
  }
  .hp_team_div1{
    gap: 3rem;
  }
  .hp_team_div11 p{
    font-size: 0.7rem;
    
}
.team_container{

  padding: 0rem 2rem;

}
}


@media (max-width: 640px) {
  .hp_team_div12 img{
    width: 300px;
  }
  .hp_team_div1{
    gap: 1rem;
    flex-direction: column-reverse;
  }
  .hp_team_div11 p{
    font-size: 0.7rem;
}
.hp_team_flex{
  flex-direction: column !important;
}
.team_container{
  display: flex;
  flex-direction: column;
  padding: 0rem 2rem;
  gap: 2rem;

}
}


@media (max-width: 475px) {
  .hp_team_br{
    display: none;
  }
  .hp_team_div12 img{
    width: 260px;
  }
  .team_container{

    padding: 0rem 1rem;
  
  }
  .hp_team_div11 p{
    font-size: 0.7rem !important;
    line-height: 0.9rem !important;
}

}
  @media (max-width: 385px) {
    .hp_team_div12 img{
      width: 200px;
    }
    .hp_team_div11 p{
      font-size: 0.6rem !important;
      line-height: 0.8rem !important;
  }
  }
