/* DoctorForm.module.css */

.formContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
   margin: 1.5rem 1rem;
    margin-bottom: 8rem;
    padding: 1rem 2rem;
  }

  .fieldwrapper{
    display: flex;
    /* justify-content: space-around; */
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    /* display: grid;
    grid-template-columns: repeat(3,1fr); */
    margin-bottom: 20px;
    gap: 1rem;
    padding: 10px 15px;
    
  }
  .fieldwrapper div{
    width: calc(33.33% - 10px);
    display: flex;
    flex-direction: column;
  }
  .timediv{
    display: flex;
    gap: 2rem;
    margin-top: 1rem;
    margin-bottom: 30px;
  }
 
  label {
    margin-bottom: 0.5rem;
    font-weight: bold;
    padding-right: 10px;
  }
.heading{
  color: rgb(44, 24, 203);
  margin-bottom: 10px;
}

  input,textarea{
    border: 1px solid black;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 15px;
   
  }
input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
textarea:focus,
select:focus {
  border-color: #5c7cfa; /* Change the border color when focused */
  box-shadow: 0 0 5px #5c7cfa; /* Add a box shadow when focused */
  outline: none; /* Remove the default outline */
}

  
  .submitButton{
    border: 1px solid white;
    padding: 10px 20px;
    background-color: blueviolet;
    width: fit-content;
    color: white;
    font-weight: bold;
    font-size: 20px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  .submitButton:hover{
    background-color: blue;
  }

  .buttondiv{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  .error {
    border-color: red;
  }
  
  .errorMessage {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }
  .fileupload{
    border: none;
    background-color: lightgray;
    color: rebeccapurple;
    cursor: pointer;
  }
  .customselect {
    display: inline-block;
    padding: 5px 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    background-color: rgb(221, 211, 220);
    transition: all 0.3s ease-in-out;
  }



  @media (max-width: 1024px) {

  }
  @media (max-width: 868px) {
    .formContainer {

      margin: 1.5rem 1rem;
       padding: 0.5rem 1rem;
     }
     .formContainer{
      width: auto;
     }
  }
  
  /* md */
  @media (max-width: 768px) {
  
  }
  
  /* sm */
  @media (max-width: 640px) {
  
  }
  
  /* xs */
  @media (max-width: 475px) {
    .fieldwrapper{
      flex-direction: column;
    }
    .fieldwrapper div{
      width: calc(97.33% - 10px);
    }
    .formContainer {

     margin: 1.5rem 0.2rem;
      padding: 0.5rem 0.2rem;
    }
    .heading{
      padding: 0 1rem;
      /* font-size: 1.1rem; */
    }
    .timediv{
      padding: 0 1rem;
      gap: 1rem;
    }
    .timediv1 input{
      font-size: 1.1rem;
      padding: 0.5rem;
    }
    .checkboxGroup{
      padding: 0 0.5rem;
    }
  
  }
    @media (max-width: 385px) {
      .fieldwrapper{
        flex-direction: column;
      }
      .fieldwrapper div{
        width: calc(95.33% - 10px);

      }
      .timediv1 input{
        width: calc(90.33% - 10px);
        font-size: 1.1rem;
        padding: 0.5rem;
      }
      .timediv{
        gap: 1rem;
      }
      .title{
        /* font-size: 1.2rem; */
      }
      .formContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
       margin: 1.5rem 0.5rem;
        margin-bottom: 8rem;
        padding: 0.5rem 0.2rem;
      }
    }

    @media (max-width: 340px){
      .formContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
       margin: 1.5rem 0.0rem;
        margin-bottom: 8rem;
        padding: 0.5rem 0.2rem;
      }
      .title{
        font-size: 1.2rem;
      }
      .heading{
        padding: 0 1rem;
        font-size: 1.1rem;
      }
      .timediv{
        padding: 0 0.5rem;
        gap: 0rem;
      }
      .timediv1 input{
        width: calc(90.33% - 10px);
        font-size: 1.1rem;
        padding: 0.25rem;
      }
      .checkboxGroup{
        padding: 0 0.5rem;
      }
    }