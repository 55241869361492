.navbar {
  background-color: #ffffff;
  height: 0px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.menu-bars {
  margin-left: 0rem;
  font-size: 2rem;
  background: none;
}

.nav-menu {
  background-color: #ffffff;
  width: 250px;
  height: 70vh;
 
  padding: 1rem;
  position: fixed;
  top: 0;
  right: 0%;
  transition: 850ms;
  border-radius: 5px;
}

.nav-menu.active {
  right: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #000000;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1a83ff;
}

.nav-menu-items {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.navbar-toggle {
  background-color: #ffffff;
  width: 100%;
  height: 60px;
  padding-right: 1rem;
  display: flex;
  justify-content: start;
  align-items: center;
}



.menu_icon {
  color: #060b26;
}

.buttonham {
  background-color: #FFFFFF;
  border: 1px solid #222222;
  border-radius: 8px;
  box-sizing: border-box;
  color: #222222;
  cursor: pointer;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  outline: none;
  padding: 8px 18px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: box-shadow .2s, -ms-transform .1s, -webkit-transform .1s, transform .1s;
  user-select: none;
  -webkit-user-select: none;
  min-width: 90px;
  width: auto;
}

.buttonham:focus-visible {
  box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
  transition: box-shadow .2s;
}

.buttonham:active {
  background-color: #F7F7F7;
  border-color: #000000;
  transform: scale(.96);
}

.buttonham:disabled {
  border-color: #DDDDDD;
  color: #DDDDDD;
  cursor: not-allowed;
  opacity: 1;
}

.menu_li a {
  font-size: 14px;
  font-weight: 400;
  color: black;
}

@media (max-width: 640px) {
  .nav-menu.active {
    left: 63%;
  }
}

@media (max-width: 530px) {
  .nav-menu.active {
    left: 50%;
  }
}







@media (max-width: 475px) {
  .buttonham {
    font-size: 14px;
    padding: 8px 17px;

  }
}
@media (max-width: 370px) {
  .nav-menu.active {
    left: 37%;
  }
  .nav-menu {
    height: 38vh;
  }
}



