.login{
    height: calc(100vh - 5rem);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:rgb(243, 236, 254) ;
    width: 100vw;
    .Logincard{
        width: 50%;
        background-color: white;
        border-radius:10px;
        display: flex;
        min-height: 500px;
        overflow: hidden;
        box-shadow: 5px 5px 5px 0px rgba(147, 138, 138, 0.5);
        .left{
            flex: 1;
           
            background-color: #7F39FB;
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 30px;
            gap: 30px;
            
            h1{
                font-size: 100px;
                line-height: 100px;
            }
            p{
            line-height: 20px;
            }
            span{
                font-size: 14px;
            }
            button{
                width: 50%;
                background-color: #ece2e2;
                border: none;
                padding: 10px;
                color: black;
                font-weight: bold;
                font-size: 15px;
                cursor: pointer;
                border-radius: 10px;
            }
        }
    .right{
        flex: 1;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        h1{
            color: #555;
        }
        form{
            display: flex;
            flex-direction: column;
            gap: 30px;  
            input{
                border-bottom: 1px solid black;
                font-size: 20px;
                padding: 10px;
            }
            button{
                border: none;
                padding: 10px;
                width: 50%;
                background-color: #5d5fcf;
                color: white;
                font-weight: bold;
                cursor: pointer;
                border-radius: 10px;
                font-size: 20px;
            }
        }
        
        div{

            button{
                width: 50%;
                background-color: #2718cd;
                border: none;
                padding: 10px;
                border-radius: 10px;
                color: white;
                font-weight: bold;
                cursor: pointer;
                
            }
           
        }
        

    }
    }
    
}