.abtTeamCard{
    position: relative;
    background: var(--color-white);
    padding: 0.6rem;
    border: 2px solid transparent;
    border-radius: var(--border-radius-2);
    transition: var(--transition);
}
.abtTeamCard:hover {
    background: transparent;
    border-color: rgb(205, 140, 255);
    transform: translateY(-0.5rem);
}
.team-img img{
    width: 160px;
    object-fit: contain;

}
.abt-card-txt{
    display: flex;
    flex-direction: column;
    gap: 0.2rem;

}
.abt-card-title{
    padding-top: 0.5rem;
    font-weight: 600;
    font-size: 20px;
}
.abt-team-link{
    color: blueviolet;
    background: transparent;
    cursor: pointer;
    font-size: 13px;
    text-decoration: underline;
    font-weight: 500;
}
.abt-card-designation{
    font-size: 13px;
    font-weight: 500;

}


.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1; 
    
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    max-width: 400px;
   
    border-radius: 8px;
    position: relative;
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  .close:hover {
    color: red;
  }
  
  