.sec3 {
   
    background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
}

.mission-title {
    padding-top: 3rem;
    font-weight: 800;
    font-size: 1.5rem;
}

.boxs {
    display: flex;
    justify-content: space-between;
}

.box {
    padding: 2rem 2rem;
    border: 1px solid white;
    border-radius: 12px;
    height: 22rem;
    width: 20rem;
    background: rgb(174, 155, 230);
    background: linear-gradient(90deg, rgba(174, 155, 230, 0.7485119047619048) 0%, rgba(141, 106, 232, 1) 100%);
    text-align: center;
}

.box h4 {
    padding-bottom: 1rem;
}

.txt2 {
    display: flex;
    justify-content: center;
  
}


.mission_container img {
    width: 8rem;
}

.box2 {
    height: 30%;
    width: 50%;
    background: red;
}

.card3 {
    box-sizing: border-box;
    width: 1055px;
    height: 300px;
    background: white;
    border: 1px solid white;
    box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22);
    backdrop-filter: blur(6px);
    border-radius: 17px;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    font-weight: bolder;
    color: black;
}

.mission_div11 {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.mission_div11 p {
    font-weight: 200;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: start;
    width: 70%;
}

.mission_div1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
}

.mission_ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.mission_ul li {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.mission_ul li span{
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.2rem;
}

.mission_div12 img {
    width: 400px;
}


@media (max-width: 1024px) {

    .mission_container {
        padding: 0rem 2rem;
    }
  }


@media (max-width: 850) {

    .mission_container {
        padding: 0rem 2rem;
    }

    .mission_div12  img {
        width: 200px;
    }
}

@media (max-width: 770px) {
    .mission_container {
        padding: 0rem 2rem;
    }
    .boxs {
        flex-direction: column;
        gap: 1rem;
    }

    .mission_container {
        align-items: center;
    }

    .mission_div1 {
       
        align-items: flex-start;
    }

    .mission_div12 img {
        width: 300px;
    }
    .mission_div11 p {
        font-weight: 200;
        font-size: 1.2rem;
        font-weight: 500;
        text-align: start;
        width: 80%;
    }
}

@media (max-width: 640px) {
    .box {
        padding: 1.5rem 1.5rem;
        border-radius: 12px;
        height: 21rem;
        width: 17rem;
    }
    .mission_div1 {
        flex-direction: column;
        align-items: flex-start;
    }

    .box h4 {
        padding-bottom: 1rem;
    }

    .box_p {
        font-size: 0.75rem !important;
        padding-bottom: 1rem;
    }

    .mission_container {
        align-items: center;
        padding: 0rem 2rem;
    }
}


@media (max-width: 475px) {
    .mission_container {
        align-items: center;
        padding: 0rem 1rem;
    }
    .mission_div12 img {
        width: 250px;
    }
    .mission_div11 p {
        font-size: 1rem;
    }
}