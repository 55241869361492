

:root {
  --input-color: #a899ba;
  --input-border: #dccded;
  --input-background: #fff;
  --input-placeholder: #CBD1DC;
  --input-border-focus: #8427fe;
  --group-color: var(--input-color);
  --group-border: var(--input-border);
  --group-background: #efeeff;
  --group-color-focus: #fff;
  --group-border-focus: var(--input-border-focus);
  --group-background-focus: #6f67fe;
}








.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1; 
  
}

.modal_content {
  background-color: #fff;
  background-image: url('../../../public/assets/modalbg.svg');
  background-size: cover;
  padding: 4rem;
  min-width: 80vw;
  min-height: 90vh;
  /* text-align: center; */
  border-radius: 8px;
  position: relative;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close {
  color: white;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.logo{
  position: absolute;
  top: 20px;
  left: 40px;
  cursor: pointer;
  width: 8vw;
}

.close:hover {
  color: red;
}

.title{
  color: #FFF;
  font-size: 7vw;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: capitalize;
}
.txt{
  color: #FFF;
text-align: center;
font-size: 1rem;
font-family: Montserrat;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
}

.div1{
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}









.button_23 {
  background-color: #FFFFFF;
  border: 1px solid #222222;
  border-radius: 8px;
  box-sizing: border-box;
  color: #222222;
  cursor: pointer;
  display: inline-block;
  font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,"Helvetica Neue",sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  outline: none;
  padding: 10px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: box-shadow .2s,-ms-transform .1s,-webkit-transform .1s,transform .1s;
  user-select: none;
  -webkit-user-select: none;
  width: auto;
}

.button_23:focus-visible {
  box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
  transition: box-shadow .2s;
}

.button_23:active {
  background-color: #902bea;
  border-color: #000000;
  color: white;
  transform: scale(.96);
}

.button_23:disabled {
  border-color: #DDDDDD;
  color: #DDDDDD;
  cursor: not-allowed;
  opacity: 1;
}

.icon{
  display: inline-block;
  font-size: 0.8rem;
}
.button_23{
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-transform: capitalize;
}


@media (max-width: 1024px) {

}

/* md */
@media (max-width: 768px) {
  .modal_content {
   
    min-height: 80vh;
  }
}

/* sm */
@media (max-width: 620px) {
  .modal_content {
   
    min-width: 60vw;
    min-height: 70vh;
  }
  .txt{

  font-size: 2vw;

  }
  .button_23{
    font-size: 11px;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    outline: none;
    padding: 8px 16px;
 }
 .icon{
  font-size: 0.8rem !important;
 }
  
}

/* xs */
@media (max-width: 465px) {
  .modal_content {
    
    min-width: 50vw;
    min-height: 60vh;
  }
  .button_23{
    font-size: 10px;
  }
}
  @media (max-width: 385px) {
    .modal_content {
     
      min-width: 50vw;
      min-height: 60vh;
    }
    .button_23{
      font-size: 10px;
    }
  }

  @media (max-width: 325px) {
    .modal_content {
      
      min-width: 50vw;
      min-height: 50vh;
    }
    .button_23{
      font-size: 10px;
    }
    .button_23{
      font-size: 6px;
      font-weight: 600;
      line-height: 20px;
      margin: 0;
      outline: none;
      padding: 4px 10px;
   }
   .icon{
    font-size: 0.6rem !important;
   }
    
  }