.container{
    width: 100%;
   
    background-color: #F3ECFE;
    padding-bottom: 3rem;
  
    
    .contactcont{
        display: flex;
        gap: 18%;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 0 5rem;

        .left{
            flex: 1;
            display: flex;
            justify-content: center;
            flex-direction: column;
            
            .imgcont{
               
                object-fit: cover;
                 transform: scaleX(-1);
                img{
                    
                    width: 450px;
                    object-fit: cover;
                    
                }
            }
        }
        .right{
            flex: 1;
            display: flex;
            flex-direction: column;
            margin: 4rem 0rem;
            .first{
                width: auto;
                height: auto;
                background-color: white;
                border-radius: 10px;
                box-shadow: 3px 3px 10px 10px rgba(227, 230, 231, 0.6);
                display: flex;
                flex-wrap: wrap;
                gap: 2rem;
                justify-content:space-between;
                padding: 2rem 1.5rem;
              
                .contact_items{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    
                    gap: 10px;
                    p{
                        font-weight: bold;
                    }
                }
            }
            .feedbutton{
                padding: 10px 20px;
                font-weight: bold;
                border-radius: 5px;
                background-color:#9D3BEA ;
                color: white;
                width: fit-content;
                margin: 2rem 0rem;
            }
            .second{
                width: 100%;
                height: auto;
                background-color: white;
                border-radius: 10px;
                box-shadow: 3px 3px 10px 10px rgba(227, 230, 231, 0.6);
               form{
                padding: 2rem 1rem ;
                width: auto;

                .submit-button {
                  
                    button{
                        padding: 10px 20px;
                        font-weight: bold;
                        border-radius: 5px;
                        background-color:#9D3BEA ;
                        color: white;
                        width: fit-content;
                        cursor: pointer;
                        margin-top: 3rem;
                        transition: all 0.2s ease;
                        &:hover{
                            background-color: rgb(98, 51, 217);
                            color: white;
                        }
                    }
                   
                }

                .bigrow{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
               
                .form-row{
                    margin-bottom: 10px;
                    display: flex;
                    flex-direction: column;
                    label{
                        display: inline-block;
                        width: fit-content;
                        margin: 10px 0;
                       font-weight: 600;
                    }
                    input{
                        width: 250px;
                        padding: 0.5rem 1rem;
                        border: 1px solid #ccc;
                        border-radius: 5px;
                        font-family: 'Poppins', sans-serif;
                    }
                  
                    .country-code{
                        width: 70px;
                    }
                    .query{
                        width: 100%;
                        height: 4rem;
                        max-height: 5rem;
                        border: 1px solid gray;
                        resize: vertical;
                        padding: 5px 10px;
                        font-family: 'Poppins', sans-serif;

                    }
                }
               }
            }
        }
    }
}
@media (max-width: 1024px) {
    .container .contactcont{
        padding: 0 2rem;
        gap: 20%;
    }
    .container .contactcont .left .imgcont img {
        width: 325px;
        object-fit: cover;
    }
}

@media (max-width: 1320px){
    .container .contactcont{
        gap: 10%;
    }
}
@media (max-width: 1180px){
    .container .contactcont{
        gap: 6%;
    }
}
@media screen and  (width<=890px) {
    .contactcont {
      flex-direction: column;
      align-items: center;
      }
      .container .contactcont .left .imgcont img {
        padding-top: 4rem;
    }
  }
  @media (max-width: 1024px) {

  }
  

  @media (max-width: 770px) {
    .container .contactcont .left .imgcont img {
        width: 360px;
        object-fit: cover;
    }



  }
  

  @media (max-width: 640px) {
    .second{

       form{
        padding: 1rem 0.5rem ;
        }
    }
    .container .contactcont .right .feedbutton{
        padding: 8px 18px;
        font-weight: bold;
        border-radius: 5px;
        background-color:#9D3BEA ;
        color: white;
        width: fit-content;
        margin: 1rem 0rem;
    }
    .container .contactcont .right .second form .form-row input {
        width: 34vw;
        padding: 0.5rem 1rem;

    }
    .container .contactcont .left .imgcont img {
        width: 300px;
        object-fit: cover;
    }

}
  
  @media (max-width: 610px) {
    .container .contactcont .right .second form .form-row input {
        width: 31vw;
        padding: 0.5rem 1rem;

    }
  }

  @media (max-width: 520) {
    .container .contactcont {
    }

  }
  @media (max-width: 475px) {
    .container .contactcont .right .second form .form-row input {
        width: 35vw;
        padding: 0.5rem 1rem;

    }
    .container .contactcont .right .second form .bigrow {
        gap: 1rem;
    }
  }

    @media (max-width: 370px) {
        .container .contactcont .right .second form .form-row input {
            font-size: 12px;
            width: 35vw;
            padding: 0.2rem 0.5rem;
        }
        .container .contactcont .right .second form .form-row label {
            font-size: 12px;

            margin: 8px 0;
            font-weight: 600;
        }
        .container .contactcont .left .imgcont img {
            width: 250px;
            object-fit: cover;
        }
        .container .contactcont .right .first .contact_items p {
            font-weight: 600;
            font-size: 12px;
        }
    }

