.event-sec {
  width: 100vw;
  background: linear-gradient(104.34deg, #E9E9E9 0%, rgba(122, 78, 247, 0.3) 0%, rgba(139, 29, 225, 0.74) 100%);

}



.event-container>h2 {
  text-align: center;
  padding-bottom: 2.5rem;
}

.event-arrow {
  width: 8px;
  display: inline;

}

.event-box {
  padding: 2rem;
  background: linear-gradient(94.23deg, rgba(57, 137, 251, 0.77) 3.62%, rgba(167, 66, 254, 0.452135) 92.17%, rgba(168, 68, 255, 0.33) 106.66%);
  width: 520px;
  height: 273px;
  height: 273px;
  border-radius: 16px;
  margin-top: 6px;
  margin-bottom: 30px;
  gap: 1rem;
  display: flex;

}

.ev-card-left {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
 
}

.ev-card-title {
  margin-left: 0;
  color: white;
  font-weight: 500;
}

.ev-card-details {
  width: 20rem;
  margin-left: 0;
  text-align: left;
  font-weight: 400;
}

.button-ev {
  background: linear-gradient(96.3deg, #237EFF 0%, rgba(40, 120, 235, 0.557292) 59.01%, rgba(57, 137, 251, 0.42) 114.45%);

  border-radius: 8px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  outline: none;
  padding: 13px 23px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: box-shadow .2s, -ms-transform .1s, -webkit-transform .1s, transform .1s;
  user-select: none;
  -webkit-user-select: none;
  width: auto;
}

.button-ev:focus-visible {
  box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
  transition: box-shadow .2s;
}

.button-ev:active {

  transform: scale(.96);
}

.button-ev:disabled {
 
  cursor: not-allowed;
  opacity: 1;
}

.ev-card-right {
  display: flex;
  position: relative;
}

.ev-card-right img {
  width: 120px;
  object-fit: contain;
}

.ev-pic2 {
  position: absolute;
  top: 55px;
  left: 80px;
}

.popular-ev-heading {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.popular-ev {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.event-box-sm {
  padding: 2rem;
  background: linear-gradient(94.23deg, rgba(57, 137, 251, 0.77) 3.62%, rgba(167, 66, 254, 0.452135) 92.17%, rgba(168, 68, 255, 0.33) 106.66%);
  height: 261px;
  width: 351px;
  border-radius: 16px;
  margin-top: 6px;
  margin-bottom: 6px;
  display: flex;
  position: relative;
}

.popular-details {
  width: 12rem;

}

.ev-gradient {
  padding: 0.5rem;
  width: auto;
  height: auto;
  border-radius: 16px;
  background: linear-gradient(96.3deg, #7710C8 0%, rgba(40, 120, 235, 0.557292) 59.01%, rgba(57, 137, 251, 0.42) 114.45%);
}

.popular-card-img>img {
  width: 100px;
  object-fit: contain;
}



.ev-img {

  width: 353px;
}

.event_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1120px) {
  .event_flex{
    flex-direction: column-reverse;
  }
  .popular-ev {
    flex-direction: column;
}
.event-left{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ev-img {
  width: 420px;
}
}
@media (max-width: 1024px) {
  .event-container{
    padding: 0rem 2rem;
  }

}


@media (max-width: 768px) {
  .event-box {
    padding: 2rem;
    width: 381px;
    height: 273px;
    height: 215px;
  }
  .event-left{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ev-img {
    width: 420px;
  }

  .ev-card-left {
    gap: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .ev-card-title {
    color: white;
    font-size: 0.8rem;
    font-weight: 500;
  }

  .ev-card-details {
    width: 10rem;
    font-size: 0.65rem;
    text-align: left;
    font-weight: 400;
  }

  .ev-card-right {
    display: flex;
    position: relative;
  }

  .ev-card-right img {
    width: 93px;
    object-fit: contain;
  }

  .ev-pic2 {
    position: absolute;
    top: 45px;
    left: 44px;
  }

  .popular-ev {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    flex-wrap: wrap;
  }
  .event-box{
    gap: 1rem;
  }

  .event-box-sm {
    padding: 2rem;
    height: 203px;
    width: 297px;
    border-radius: 16px;
    margin-top: 6px;
    margin-bottom: 6px;

  }

  .popular-details {
    width: 11rem;

  }
  .event-container{
    padding: 0rem 2rem;
  }

}


@media (max-width: 640px) {
  .event_flex {
    flex-direction: column-reverse;
    padding-bottom: 1.5rem;
    align-items: center;
  }
  .event-container{
    padding: 0rem 2rem;
  }
  .ev-img {
    width: 400px;
  }
}

@media (max-width: 475px) {
  .ev-img {
    width: 300px;
  }

  .event-box {
    padding: 2rem;
    width: 291px;
    height: 176px;
  }
  .event-container{
    padding: 0rem 1rem;
  }

  .ev-pic2 {
    position: absolute;
    top: 26px;
    left: 44px;
  }

  .ev-card-left {
    gap: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .ev-card-title {
    font-size: 0.7rem;
    font-weight: 500;
  }

  .ev-card-details {
    width: 8rem;
    font-size: 0.5rem;
    text-align: left;
    font-weight: 400;
  }



  .ev-card-right img {
    width: 60px;
    object-fit: contain;
  }

  .ev-pic2 {
    position: absolute;
    top: 45px;
    left: 44px;
  }

  .popular-ev {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    flex-wrap: wrap;
  }

  .event-box-sm {
    padding: 1.5rem;
    display: flex;
    gap: 1.5rem;
    height: 170px;
    width: 264px;
    border-radius: 16px;
    margin-top: 6px;
    margin-bottom: 6px;

  }

  .popular-details {
    width: 8.5rem;

  }

  .button-ev {
    border-radius: 8px;
    font-size: 11px;
    font-weight: 500;
    line-height: 4px;
    padding: 11px 18px;
  }

  .event-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 385px) {
  .event-box {
    
    padding: 1.5rem;
    gap: 0.5rem;
    height: 170px;
    width: 264px;
    border-radius: 16px;
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .ev-img {
    width: 250px;
  }
  .ev-pic2 {
    position: absolute;
    top: 45px;
    left: 44px;
  }
  .ev-card-details{
    width: 7rem;
  }
  .popular-ev-heading{
    padding-top: 3rem;
  }
  .ev-card-right img {
    width: 56px;
    object-fit: contain;
}
}