.mainContainer{
    height: 100vh;
    padding: 2rem 1rem;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 8rem;
}


.firstdiv,.seconddiv{
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding: 1.5rem 1rem;
}
.seconddiv{
    margin-top: 8rem;
   flex-direction: row-reverse; 
   gap: 5rem;
   padding: 2rem 6rem;
   
}
.imgdiv{
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 50%;
    
}
.imgdiv img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.title{
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;
}
.item{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}
.item span{
    font-weight: bold;
}
.icon{
   color: #A634FF;
}

.speciality{
    border: none;
    padding: 10px 20px;
    background-color: #B051EF;
    color: white;
    width: fit-content;
    border-radius: 10px;
}

.DatePicker{
    margin-top: 1rem;
}
input[type="date"]{
    border: none;
    border: 1px solid rgb(153, 70, 212);
    cursor: pointer;
    background-color: #B051EF;
    color: white;
    
}
.consultation{
    margin-top: 1.5rem;
}
.bottomleft{
    display: flex;
    flex: 3;
    flex-direction: column;
    gap: 1rem;
    padding-top: 4rem;
   
}

.bookbtn{
   
    width: 200px;
    padding: 1rem 2rem;
    border: none;
    background-color:#A634FF;
    color: white;
    border-radius: 10px;
    font-weight: bold;
    cursor:pointer;
}
.btnOutline{
    border:2px solid black;
    padding: 1rem 2rem;
    width: 200px;
    border-radius: 10px;
    font-weight: bold;
    background-color: white;
    cursor:pointer;
}
.slotbtn{
    border: none;
    background-color: white;
    font-size: 2rem;
    margin-bottom: 2rem;
    border-bottom: 2px solid black;
}




.timeselectContainer{
    flex: 8;
    
}
.timeContainer{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(200px,1fr));
    gap: 2rem;
    margin-bottom: 1.5rem;
}
.problemarea{
    width: 30vw;
    height: 200px;
    font-family: 'Pangolin', cursive;
    resize: none;
}



@media  screen and (width<=768px) {
    .firstdiv,.seconddiv{
        flex-direction: column;
        align-items: center;
        gap: 3rem;
    }
    .right{
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
    }
    .availdays{
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        justify-content: center;
        align-items: center;
    }
    .secondiv{
        flex-direction: column;
    }
    .timeselectContainer{
        height: 70vh;
        margin-top: -3rem;
        grid-template-columns: repeat(3,minmax(100px,1fr));
        gap: 1rem;
    }
    
}