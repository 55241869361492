.appointments-table {
    margin: 20px;
  }
  
  .appointments-table .ant-table {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .appointments-table .ant-table thead th {
    background-color: #8c52f0;
    
  }

  .profile-style {
    background-color:  #b4baea66;
    height: 100vh;
    padding: 1rem 2rem;
  }
  
  
  
  