.abtcardWrapper {
  display: flex; 
  flex-wrap: wrap; 
  justify-content: flex-start; 
  gap: 4rem;
}

.abt-cards{
    display: flex;
    gap: 2rem;
}

