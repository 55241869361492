.service-sec {
    background: #F3ECFE;
    font-family: var(--fontfamily_1);
}


.service-container{
    display: flex;
    flex-direction: column;
    width: 100vw;
}
.service-div1{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 5rem;
}
.service-missionsvg img{
    width: 400px;
}
.service-mission-txt h1{
    font-size: 35px;
    padding-bottom: 1rem;
    color: #4643E7;
}
.service-mission-txt p{
    font-weight: 500;
    color: #454444;
}
.service-mission-txt ul{
    list-style: none;
    padding-left: 1.3rem;
}
.service-mission-txt ul li{
    padding-bottom: 10px;
    position: relative;
}
.service-mission-txt ul li::before{
    content: '';
    position: absolute;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #9959FF;
    top: 7px;
    left: -1rem;
}

.service-mission-txt ul li span{
    font-size: 20px;
    font-family: 'Noto Sans', sans-serif;
}
.servicebtn{
    border: none;
    padding: 10px 20px;
    width: fit-content;
    background-color:#9959FF;
    border-radius: 10px;
    font-weight: bold;
    color: white;
    border: 1px solid black;
    margin-left: 0.5rem;
    margin-top: 1rem;
    cursor: pointer;
}


/* ################################# div2 ####################################### */

.service-div2{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 36rem;
    background-color: #885CF6;
    align-items: center;
}
.service-story-txt h1{
    font-size: 35px;
    padding-bottom: 1rem;
    color: white;
    
}
.service-story-txt p{
    font-weight: 300;
    color: #454444;
    line-height: 1.5rem;
    max-width: 500px;
    word-break: keep-all;
    font-family: var(--fontfamily_1);
    color: white;
}
.service-storysvg img{
    width: 400px;
}

.service_div2{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 4rem 4rem;
    background-color: #885CF6;

}


/* ################################# div3 ####################################### */
.our-session{
    background: rgb(92,116,246);
    background: linear-gradient(169deg, rgba(92,116,246,1) 30%, rgba(146,12,207,1) 70%);
    margin-bottom: -5rem;
   
}



@media (max-width: 1024px) {

}

/* md */
@media (max-width: 768px) {
    
    .service-missionsvg img{
        width: 280px;
    }
    .service-div1{
        flex-direction: column;
        gap: 2rem;  
    }
    .service-storysvg img{
        width: 300px;
    }
    .service-story-txt p{
        font-size: 0.8rem;
        width: 80%;
    }
    .service-div2{
        flex-direction: column-reverse;
        padding: 2rem 2.5rem;
        align-items: center;
        gap: 2rem;
    }
    .service_div2{
        flex-direction: column-reverse;
        padding: 4rem 2.5rem;
        align-items: center;
    }
    .service-story-txt{
        
    }

}

