
.mainContainer{
    height: 100vh;
    
}
.notifmaincontainer{
    
    margin-bottom: 1rem;
}
.sortcont{
    display: flex;
    align-items: center;
    
}

.docnotifiCont{
    display: flex;
    flex-direction: column  ;
    padding: 1rem 2rem;
    
   
}
.notifictitle{
    box-shadow: 5px 5px 10px 0px rgba(111, 130, 205, 0.6);
    padding: 1rem 1.5rem;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
   
    font-size: 1rem;
    position: relative;
  
   
}
.title{
    font-weight: 400;
    text-transform: capitalize;
    color: blueviolet;

}
.bookedtime{
    position: absolute;
    bottom: 5px;
    right: 15px;
    font-size: 0.8rem;
    color: rgb(152, 145, 145);
    
}


