.container{
    height: calc(100vh - 50px );
    overflow-y: hidden;
    overflow-x: hidden;
     background-color: #b4baea66;
 }
 .heading{
     display: flex;
     align-items: center;
     padding: 0 1.5rem;
     margin-top: 1rem; 
 }
 .heading h2{
     font-size: 30px;
     margin-top: 5px;
 }
 .mainsection{
     display: flex;
     width: 100vw;
     height: 80%;
    
     justify-content: space-around;
 }
 .left{
     flex: 2;
     border-right: 2px solid black;
     display: flex;
     flex-direction: column;
     padding: 0 1.5rem;
     height: 100%;
     margin-right: 2rem;
     
 }
 .profilepic{
     position: relative;
     width: 150px;
     height: 150px;
     border-radius: 50%;
     margin-top: 1rem;
     
 }
 .username{
     font-family: 'Roboto', sans-serif; 
     font-size: 30px;;
 }
 .userimg{
     width: 150px;
     height: 150px;
     border-radius: 50%;
     object-fit: cover;
     border: 2px solid white;
 }
 .userimg img{
     height: 100%;
     width: 100%;
     border-radius: 50%;
 }
 .editicon{
     position: absolute;
     right: -10px;
     bottom: 10px;
     border: 2px solid black;
     background-color: rgb(149, 83, 230);
     color: white;
     width: fit-content;
     padding: 10px;
     display: flex;
     align-items: center;
     justify-content: center;
     border-radius: 50%;
     cursor: pointer;
 }
 .about{
     max-width: 19rem;
     font-family: 'Poppins', sans-serif;
     font-weight: 200;
     word-spacing: 2px;
 
 }
 
 .userdetail{
     display: flex;
     flex-direction: column;
    align-items: flex-start;
     margin-top: 2rem;
 }
 
 .center{
    flex: 3;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem 1.5rem 0 1.5rem;
    
 }
 .name{
     display: flex;
     align-items: center;
     gap: 2rem;
 }
 .title{
     font-weight: 600;
     font-size: 1rem;
     min-width: 100px;
 }
 .inputfld{
     border: none;
     outline: none;
     padding: 0.5rem 1rem;
     border-radius: 10px;
     margin-top: 5px;
     width: 80%;
     box-sizing: border-box;
 }
 
 .genderdiv{
     display: flex;
  align-items: center;
  gap: 2rem;
   margin-top: 5px;
 }
 
 
 .btn{
     border: 1px solid black;
     border-radius: 10px;
     font-size: 20px;
     background-color: rgb(149, 83, 230);
     color: white;
     font-weight:bold;
     cursor: pointer;
     padding: 0.5rem;
     max-width: 100px;
 
 }
 .right{
     flex:3;
     max-width: 800px;
     margin: 0 1rem;
     padding: 20px;
     
 }
 .buttonscontainer{
     display: flex;
     align-items: center;
     gap: 3rem;
     margin-bottom: 1rem;
     
 }
 .appointbtn{
     border: none;
     background-color: inherit;
     font-size: 15px;
     cursor: pointer;
 }
 .active{
     border: none;
     background-color: inherit;
      font-size: 15px;
      font-weight: bold;
      color: rgb(161, 108, 192);
     
 }
 .cardscontainer{
     display: flex;
     flex-direction: column;
     gap: 1rem;
     max-height: calc(100vh - 200px); 
     overflow-y: auto;
 }
 
 