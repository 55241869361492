
.dropdown-menu {
    position: absolute;
    top: calc(100% + 2px);
    top: 37px;
    right: 0;
    background-color: #fff;
    
    border-radius: 4px;
    padding: 8px;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .menu-item {
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    transition: background 500ms;
    padding: 0.5rem;
    color: black;
    gap: 0.2rem;
  }

  .icon-button {
    background-color: #7c33fa;
    border-radius: 50%;
    padding: 5px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
  }

  .menu-item:hover {
    background-color: #c6c6c6;
  }

  .icon-button:hover {
    filter: brightness(1.2);
  }
  .icon-button svg { 
    fill: #ffffff;
    width: 20px;
    height: 20px;
  }
  .menu-item .icon-button:hover {
    filter: none;
  }