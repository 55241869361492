.header{
    padding-bottom: 4rem;
    background: #7F39FB;
    width: 100vw;
    height: auto;
    position: relative;
}
.header-container{
    display: flex;
    justify-content: space-between;
}
.herotitle{
  font-size: 4rem;
}

.hero-left{
    padding-top: 3rem;
}
.des p{
    padding-top: 0.8rem;
    padding-bottom: 2rem;
    font-size: 1rem;
    line-height: 1.4rem;   
    color: white;
    max-width: 30rem;
    font-family: 'Noto Sans', sans-serif;
    word-break: keep-all;
    font-weight:300;
}
.hero-title>h1{
  
    font-weight: 900;
    color: white;
    
    
}

.button-hero {
    background-color: #FFFFFF;
    border: 1px solid #222222;
    border-radius: 8px;
    box-sizing: border-box;
    color: #222222;
    cursor: pointer;
    display: inline-block;
    font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,"Helvetica Neue",sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    outline: none;
    padding: 13px 23px;
    position: relative;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation;
    transition: box-shadow .2s,-ms-transform .1s,-webkit-transform .1s,transform .1s;
    user-select: none;
    -webkit-user-select: none;
    width: auto;
  }
  
  .button-hero:focus-visible {
    box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
    transition: box-shadow .2s;
  }
  
  .button-hero:active {
    background-color: #F7F7F7;
    border-color: #000000;
    transform: scale(.96);
  }
  
  .button-hero:disabled {
    border-color: #DDDDDD;
    color: #DDDDDD;
    cursor: not-allowed;
    opacity: 1;
  }

  
  .hero-right{
    display: flex;
    align-items: flex-start;
    height: 300px;
   
    object-fit: cover;
  }
  .hero-right>img{
    width: 100%;
    height: 100%;
    padding-top: -3rem; 
  }



  
  /* lg */
  @media (max-width: 1024px) {
    .header-container{
      padding: 0rem 4rem;
    }
  }
  

  @media (max-width: 768px) {
    .herotitle{
      font-size: 3rem;
    }
    .des p{
      font-size: 0.6rem;
    line-height: 0.8rem;
    max-width: 300px;
    }
    .hero-right>img{
      width: 250px !important;
    }
    .header-container{
      gap: 5rem;
    }
    .header-container{
      padding: 0rem 4rem;
    }
  }
  

  @media (max-width: 640px) {
    .header-container{
      flex-direction: column;
      padding: 0rem 4rem;
      gap: 1rem;
    }
  }
  

  @media (max-width: 360px) {
    .hero-right img{
      width: 178px !important;
    }
    .hero-title>h1 {
      font-size: 2rem;
  }

  }
  