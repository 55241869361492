.doc_pg_outer{
    margin-top: 0px;
    height: 8rem;
    width: 100vw;
    background: rgb(126,49,208);
    background: radial-gradient(circle, rgba(126,49,208,1) 35%, rgba(175,0,255,0.9697128851540616) 100%);
    position: relative;
  }
 .maindiv{
    padding: 2rem 1rem;
    background-color: #b4baea66;
    height: auto;
  }

  .Details{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -2rem;
    padding: 0 2rem;
  }
 
 
  .appointment{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0rem 2rem;
    margin-top: 6rem;
  }
.appointfirst{
  flex: 4;
}
  .appointsecond{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    flex: 6;
   
  }

  .timeselectContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .timeContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 2rem;
  }

    
  
  .doc_pg_inner{
    position:absolute;
    height: 150px;
    width: 150px;
    top: 50px;
    border-radius: 50%;
    left: 20px;
    z-index: 999;
    margin-top: 5rem;
    margin-left: 2rem;
  }
  .indi_docimg{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 5px solid white;
    z-index:-1;
  }
  .icon{
    font-size: 20px;
    border: 1px solid black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3rem;
    background-color: #b031d0;
    color: white;
  }
  .icondiv{
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 10px;
   
  }
  .icondiv p{
    font-size: 1rem;
    color: rgb(50, 47, 47);
    font-weight: 500;
  }
  .icondiv h3{
    border-bottom: 2px solid black;
  }
 
  .name{
    font-size: 1.5rem;
  }
 
  
  
  
  .specialization{
    /* flex: 4; */
    height: fit-content;
    background-color: white;
    border-radius: 10px;
    padding: 1rem 1.5rem;
    line-height:1.5rem;
    font-family: 'Poppins', sans-serif;
    margin-top: 10px;
  }
  .indiItemPersonal{
    display: flex;
    flex-direction: column;
   margin: 10px 0;
  }
  .indiItemPersonal p{
    font-weight: 500;
  }
  .detailicon{
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }

  .personalDetails{
    display: flex;
    flex-direction: column;
    flex:2;
    gap: 1rem;
    padding: 0rem 2rem;
    
    /* background-color: white; */
  }
  .perdeticon{
    color: rgb(91, 91, 241);
  }
  .daysDiv{
   display: flex;
   flex-direction: column;  
   gap: 10px;
   margin-top: 10px;
  }
  .headingappointment{
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    padding: 0.4rem 0rem;
    color: rgb(90, 74, 214);
    font-size: 1.5rem;
    font-weight: bold;

  }
  .problemarea{
    width: 30vw;
    height: 200px;
    font-family: 'Pangolin', cursive;
    resize: none;
    margin-top: 1rem;
  }
  
  
  .star_rating {
    font-size: 24px; /* Adjust the font size as needed */
    color: rgba(221, 190, 18, 0.581); /* Set the color of the stars */
  }
  
  .star {
    display: inline-block;
  }
  .bookbtn{
    padding: 1rem 2rem;
    color: white;
    min-width: 200px;
    cursor: pointer;
    background-color:rgb(126,49,208);
    border-radius: 10px;
    border:1px solid black;
    width:fit-content;
    
  }
  .primary_btn{
    padding: 1rem 2rem;
    color: black;
    min-width: 200px;
    background-color: white;
    border-radius: 10px;
    border:1px solid black;
    width:fit-content;
    cursor: pointer;
    font-size: 15px;
   
  }
  .about{
    width: 600px;
    height: auto;
    margin-top: 2rem;
    background-color: white;
    border-radius: 10px;
    padding: 1rem 1.5rem;
    line-height:1.5rem;
    font-family: 'Poppins', sans-serif;
  }
  .abouticondiv{
    display: flex;
    align-items: center;
    gap: 5px;
    border-bottom: 2px solid gray;
    padding-bottom: 0.3rem;
    margin-bottom: 10px;
    
  }

  .bookbtn{
    /* width: fit-content; */
    width: 200px;
    padding: 1rem 2rem;
    border: none;
    background-color:#A634FF;
    color: white;
    border-radius: 10px;
    font-weight: bold;
    cursor:pointer;
}
.btnOutline{
    border:2px solid black;
    padding: 1rem 2rem;
    width: 200px;
    border-radius: 10px;
    font-weight: bold;
    background-color: white;
    cursor:pointer;
}
.slotbtn{
    border: none;
    background-color: inherit;
    font-size: 2rem;
    margin-bottom: 2rem;
    border-bottom: 2px solid black;
}


.bottomleft{
  display: flex;
  flex: 3;
  flex-direction: column;
  gap: 1rem;
  padding-top: 4rem;
}



  .abouticondiv span{
    font-weight: bold;
  }
  .button_container {
    display: flex;
    margin-top: 3rem;
  }
  
  .video-call-button,
  .chat-button {
    background-color: #7e31d0;
    color: white;
    border: 1px solid black;
    padding: 10px 20px;
    border-radius:10px; 
    border: 1px solid black;
    cursor: pointer;
    min-width: 100px;
  width:fit-content;
  }
  
  .video-call-button {
    margin-right: 10px; /* Add spacing between buttons if needed */
  }
  
  .doc_speciality{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    
  }
  ul.doc_speciality {
    list-style-type: none; /* Remove default bullet points */
    padding-left: 0; /* Remove default left padding */
  }
  
  ul.doc_speciality li {
    position: relative; /* Set position relative for pseudo-element */
    padding-left: 20px; 
    
  }
  .indi_speciality{
    font-size: 15px;
    font-weight: normal;
  }
  
  ul.doc_speciality li::before {
    content: "✔"; /* Add tick symbol as content */
    position: absolute; /* Position the tick symbol */
    left: 0; /* Position it at the left side */
    top: 0; /* Position it at the top */
   color: blue;
   
  }

  @media screen and (width<=768px){
    .maindiv{
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .div-2{
      flex-direction: column;
    }
  }