nav {
    width: 100vw;
    height: 5rem;
    display: grid;
    place-items: center;
    background: rgb(248, 248, 248);
    position: relative;
    top: 0;
    left: 0;
    z-index: 9;
   
    box-shadow: 0px -24px 35px rgba(0, 0, 0, 0.33), 0px 10px 22px rgba(36, 33, 42, 0.23);
    transition: var(--transition);
}

.nav__container {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav__logo {
    
    width: 9rem;
    overflow: hidden;
}

.nav__menu {
    display: flex;
    gap: 3.5rem;
}
.nav-item{
  font-weight:600;
}
.nav__menu a:hover{
    font-weight: 500;
    border-bottom: 1px solid white; 
    padding-bottom: 2px; 
    transition: border-bottom 0.3s ease-in-out; 
    
    
}

.nav__menu a {
    color: black;
    
}

.button-23 {
  background-color: #FFFFFF;
  border: 1px solid #222222;
  border-radius: 8px;
  box-sizing: border-box;
  color: #222222;
  cursor: pointer;
  display: inline-block;
  font-family: var(--fontfamily_1);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  outline: none;
  padding: 10px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: box-shadow .2s,-ms-transform .1s,-webkit-transform .1s,transform .1s;
  user-select: none;
  -webkit-user-select: none;
  width: auto;
}

.button-23:focus-visible {
  box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
  transition: box-shadow .2s;
}

.button-23:active {
  background-color: #F7F7F7;
  border-color: #000000;
  transform: scale(.96);
}

.button-23:disabled {
  border-color: #DDDDDD;
  color: #DDDDDD;
  cursor: not-allowed;
  opacity: 1;
}
.nav-btns{
    display: flex;
    gap: 0.5rem;
}










@media screen and (max-width: 1024px) {
  .nav_user_name{
    padding-left: 1rem;
    padding-right: 1rem;
    white-space: nowrap;
  }
}

.nav_user_name{
  cursor: pointer;
  font-weight: 500;
}
.nav_user_name:hover{
  color: #7428f8;
}
@media (max-width: 825px){
  .nav__logo img {
    
    width: 50px;
    overflow: hidden;
}
.nav__container{
  padding-left: 1rem;
  padding-right: 1rem;
}
.nav__menu a {
  color: black;
  font-size: 0.75rem;
}
.nav__menu {
  display: flex;
  gap: 1.5rem;
}
.nav_user_name{
  font-size: 0.75rem;
  padding-top: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  white-space: nowrap;
}
} 


@media (max-width: 768px) {
  .nav__logo img {
   
    width: 50px;
    overflow: hidden;
}
.nav__container{
  padding-left: 1rem;
  padding-right: 1rem;
}
.nav__menu a {
  color: black;
  font-size: 0.75rem;
}
.nav__menu {
  display: flex;
  gap: 1.5rem;
}
.nav_user_name{
  font-size: 0.7rem;
  padding-top: 0.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  white-space: nowrap;
}



}


@media (max-width: 980px) {
 
.button-23{
  padding: 0.5rem 1rem;
  font-size: 14px;
}
.login_btn{
  margin-left: 2rem;
}
.nav__menu a {
  font-size: 0.8rem;
}
}
@media (max-width: 830px) {
  
.button-23{
  padding: 0.5rem 1rem;
  font-size: 14px;
}
.login_btn{
  margin-left: 0.8rem;
}

}

@media (max-width: 730px) {
 
.button-23{
  padding: 0.5rem 0.8rem;
  font-size: 12px;
}
.login_btn{
  margin-left: 0.8rem;
}
.nav__menu a {
  font-size: 0.7rem;
}
}
@media (max-width: 640px) {
  .nav__menu {
    display: none;
}
}

@media (max-width: 475px) {
  nav{
    height: 3rem;
  }

  .nav__logo  img{
    width: 42px;
}
.nav_user_name{
  font-size: 0.8rem;
}
}

@media (max-width: 385px) {
  .button-23 {
    font-size: 10px;
    font-weight: 600;
    line-height: 13px;
    padding: 10px 20px;
  }
  nav{
    height: 3rem;
  }
  
  .nav__logo  img{
   
    width: 40px;

}
.nav_user_name{
  font-size: 0.7rem;
}
}
@media (max-width: 300px) {
  .button-23 {
    font-size: 9px;
    font-weight: 600;
    line-height: 10px;
    padding: 10px 15px;
  }
}

.dropdown-menu{
  display: none !important;
}

.nav_dropdownmenu_div{
  
}

.nav_user_name:hover .dropdown-menu{display: block !important; }

.ham_menu{
  display: none;
}


@media (max-width: 640px) {
  
.nav_dropdownmenu_div,.button-23{
  display: none !important;
}
.ham_menu{
  display: block;
}
.nav__container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  gap: 30vw;
}

}

@media (max-width: 460px) {

  .nav__container{
    display: flex;
    align-items: center;
    justify-content: space-between;
   
    gap: 20vw;
  }
}
@media (max-width: 404px) {

  .nav__container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    gap: 20vw;
  }
}
@media (max-width: 350px) {

  .nav__container{

    gap: 11vw;
  }
}
@media (max-width: 295px) {

  .nav__container{
  
    gap: 8vw;
  }
  
  }
  
@media (max-width: 230px) {

  .nav__container{
  
    gap: 6vw;
  }
  
  }

